@import './globalStyles/StyleVariables.scss';

.spin {
  position: absolute;
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  margin-left: -50px;
  margin-top: -25px;
}

:root {
  scrollbar-color: $gray80 $cardColor !important;
  scrollbar-width: thin !important;
}

body {
  /* For Firefox */
  overflow-y: scroll;

  /* For Chrome and other browsers except Firefox */
}

* {
  scrollbar-color: $gray80 $cardColor;
  scrollbar-width: thin !important;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    overflow-y: auto;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: $gray80;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 0;
    border-radius: 0;
    background: $cardColor;
  }
}

#loading {
  display: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 7px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(252, 4, 4, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 7px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 7px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation-reverse {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.3, 1.3);
  }
  100% {
    transform: scale(1, 1);
  }
}

@media print {
  .no-print {
    display: none !important;
  }
}
